/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */
import type Slug from '~/src/Domain/Shared/Identifier/Slug'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'

export default function useDataRequestNavigation() {
  const { navigateTo, navigateToLocalePath, getLocalePath, route } = useNavigation()

  const getIntroductionStepLocalePath = (
    dataRequestId: string,
    dataRequestSlug: Slug,
    dataRequestRecipientId: string,
  ) =>
    getLocalePath({
      name: 'dataRequest-IntroductionStep',
      params: {
        dataRequestId,
        dataRequestSlug: dataRequestSlug.toString(),
        dataRequestRecipientId,
      },
    })

  const getStepLocalePath = (
    dataRequestId: string,
    dataRequestSlug: Slug,
    dataRequestRecipientId: string,
    stepSlug: Slug,
  ) =>
    getLocalePath({
      name: 'dataRequest-Step',
      params: {
        dataRequestId,
        dataRequestSlug: dataRequestSlug.toString(),
        dataRequestRecipientId,
        stepSlug: stepSlug.toString(),
      },
    })

  const getSummaryStepLocalePath = (
    dataRequestId: string,
    dataRequestSlug: Slug,
    dataRequestRecipientId: string,
  ) =>
    getLocalePath({
      name: 'dataRequest-SummaryStep',
      params: {
        dataRequestId,
        dataRequestSlug: dataRequestSlug.toString(),
        dataRequestRecipientId,
      },
    })

  const navigateToAuthPage = async () => {
    await navigateToLocalePath('dataRequest-Authorise')
  }

  const navigateToAuth2faPage = async () => {
    await navigateToLocalePath('dataRequest-Authorise2fa')
  }

  const navigateToLandingPage = async (
    dataRequestId: string,
    dataRequestSlug: Slug,
    dataRequestRecipientId: string,
  ) => {
    await navigateToLocalePath({
      name: 'dataRequest-Landing',
      params: {
        dataRequestId,
        dataRequestSlug: dataRequestSlug.toString(),
        dataRequestRecipientId,
      },
    })
  }

  const navigateToIntroductionStepPage = async (
    dataRequestId: string,
    dataRequestSlug: Slug,
    dataRequestRecipientId: string,
  ) => {
    await navigateTo(
      getIntroductionStepLocalePath(dataRequestId, dataRequestSlug, dataRequestRecipientId),
    )
  }

  const navigateToSuccessStepPage = async (
    dataRequestId: string,
    dataRequestSlug: Slug,
    dataRequestRecipientId: string,
  ) => {
    await navigateToLocalePath({
      name: 'dataRequest-SuccessStep',
      params: {
        dataRequestId,
        dataRequestSlug: dataRequestSlug.toString(),
        dataRequestRecipientId,
      },
    })
  }

  return {
    route,
    getIntroductionStepLocalePath,
    getStepLocalePath,
    getSummaryStepLocalePath,
    navigateToAuthPage,
    navigateToAuth2faPage,
    navigateToLandingPage,
    navigateToIntroductionStepPage,
    navigateToSuccessStepPage,
  }
}
